import { React, useState } from 'react'
import { Button } from 'primereact/button'
import { Card } from 'primereact/card';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { DataTable } from 'primereact/datatable';
import { useTranslation, Trans } from "react-i18next";
import { Column } from 'primereact/column';

const ImportPreview = ({ handleBack, handleContinue, navigateToImportPage, filteredRowsNoZero, validFilteredRows, importBusinessCustomers, defMembership, setState, state, nonDuplicateNumbers }) => {
    const { t } = useTranslation("common");

    const [activeIndex, setActiveIndex] = useState(null);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);

    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);
    };

    const handleTabChange = (e) => {
        if (e.index === 0) {
            return;
        }
        setActiveIndex(e.index);
    };
    const convertArrayToCSV = (data) => {
        // Check if data is an array and contains at least one object
        if (!Array.isArray(data) || data.length === 0) {
            return "";
        }
        // Extract headers from the first object's keys
        const headers = Object.keys(data[0]);
        // Map over data to create each row as a CSV string
        const rows = data.map(row =>
            headers.map(header => JSON.stringify(row[header] || "")).join(",")
        );
        // Join headers and rows with newline characters
        return [headers.join(","), ...rows].join("\n");
    };

    const createCSVFile = (csvString) => {
        return new Blob([csvString], { type: 'text/csv' });
    };

    const csvString = convertArrayToCSV(nonDuplicateNumbers);

    // Create a file from the CSV string
    const csvBlob = createCSVFile(csvString);
    const updatedFile = new File([csvBlob], 'nonDuplicateNumbers.csv', { type: 'text/csv' });

    const handleImportPreviewContinue = () => {
        let file = updatedFile;
        const formData = new FormData();
        formData.append("file", file, file.name);

        importBusinessCustomers(defMembership.business.id, defMembership.id, formData);
        handleContinue();
    }

    const clearForImport = validFilteredRows.length;
    const notClearForImport = filteredRowsNoZero.length;

    const columns = [
        { field: "First name", header: "First Name" }, { field: "Last name", header: "Last Name" },
        { field: "Phone number", header: "Phone Number" }, { field: "Reference", header: "Reference" }
    ]

    return (
        <div>
            <Card className='main-upload-card'>
                <div className='pl-4 pt-5'>
                    <p className='title-text'>{t("business_customers.import-preview-header")}</p>
                    <p className='sub_text mb-0'>{t("business_customers.preview_text")}</p>
                    <div className='import-preview-accordion'>
                        <Accordion activeIndex={activeIndex} onTabChange={handleTabChange} className='mb-3 mt-2'>
                            <AccordionTab header={<Trans i18nKey="business_customers.accordion_header1" values={{ value: clearForImport }} components={{ 1: <strong style={{ color: 'green' }} /> }} />}
                                className='success'>
                            </AccordionTab>
                            {(notClearForImport > 0) && <AccordionTab header={<Trans i18nKey="business_customers.accordion_header2" values={{ value: notClearForImport }} components={{ 1: <strong style={{ color: 'red' }} /> }} />}
                            >
                                <DataTable value={filteredRowsNoZero} paginator first={first} rows={rows} totalRecords={notClearForImport}
                                    onPage={onPageChange} className="p-datatable-responsive-table data_table custom-table-style">
                                    {columns.map(({ header, field }, i) => <Column key={i} header={header} field={field} />
                                    )}
                                </DataTable>
                            </AccordionTab>}
                        </Accordion>
                    </div>

                    <div className='buttons-cont'>
                        <div className='back-btn mt-6'>
                            <Button className='import_back_button' onClick={handleBack} >
                                {t("Back")}
                            </Button>
                        </div>
                        <div className='cont-btn'>
                            <Button className='import_cancel_button' onClick={navigateToImportPage} >
                                {t("cancel")}
                            </Button>
                            <Button className='import_button' onClick={handleImportPreviewContinue} >
                                {t("continue")}
                            </Button>
                        </div>
                    </div>

                </div>
            </Card>
        </div>
    )
}

export default ImportPreview