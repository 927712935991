import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import PaymentTrackActions from "../../Redux/transition/payment-track/payment-track.reducer";
import DefMembershipActions from "../../Redux/actions/defMembership-actions";
import moment from "moment";
import { Button } from 'primereact/button';
import "../../Styles/pages/payment.scss";
import { useReactToPrint } from 'react-to-print';
import logo from "../../assets/images/Reveknew_Logo_Colour_and_Black.png";
import Loading from "../../Components/Loading";

const PaymentDetail = () => {

    const componentRef = useRef(null);
    const { t } = useTranslation("common");
    const { id: paymentTrackId } = useParams();
    const newDate = moment().format("ll");

    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [componentRef.current]);

    const handlePrint = useReactToPrint({
        content: reactToPrintContent
    });

    const [state, setState] = useState({
        paymentTrack: null
    })

    //Redux State from Store
    const defMembership = useSelector((state) => state.defMembership.defMembership);
    const paymentTrack = useSelector((state) => state.paymentTracks.paymentTrack);
    const fetchingOnePaymentTrack = useSelector((state) => state.paymentTracks.fetchingOne);

    //Redux Dispatch Actions
    const dispatch = useDispatch();
    const getDefMembership = useCallback(() => { dispatch(DefMembershipActions.defMembershipRequest()); }, [dispatch])
    const getPaymentTrack = useCallback((paymentTrackId) => dispatch(PaymentTrackActions.paymentTrackRequest(paymentTrackId)), [dispatch]);

    useEffect(() => {
        if (!defMembership) {
            getDefMembership();
        }
        if (defMembership) {
            getPaymentTrack(paymentTrackId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {

        if (paymentTrack) {
            setState((state) => { return { ...state, paymentTrack } })
        }
    }, [paymentTrack])

    if (fetchingOnePaymentTrack) {
        return <Loading />
    }
    const amountPaid = paymentTrack?.amount
    const setup = state.paymentTrack?.setupAmount
    const total = amountPaid + setup
    return (
        <div >
            <Button label="Print" icon="pi pi-print"
                onClick={handlePrint}
                id="print_btn" />

            <div className={"section-to-print print_section"} ref={componentRef}>
                <div className='bus_info_main_div'>
                    <div className="div_logo_side">
                        <img src={defMembership.business.logoUrl} alt='' className="businessLogoStyle" />
                        <span className='invoice_txt'>{t('payments.receipt')}</span>
                        <span className='invoice_val'>{state.paymentTrack?.reference}</span>
                    </div>
                    <div className='bus_info_div'>
                        <span className='bus_name'>{state.paymentTrack?.business.name}</span>
                        <span className='bus_info busNo'>{defMembership.business.country.phoneCode}{state.paymentTrack?.business.phoneNo.substring(1)}</span>
                        <span className='bus_info busEmail'>{state.paymentTrack?.business.email}</span>
                        <span className='bus_info'>{state.paymentTrack?.business.addressLine1} </span>
                    </div>
                </div>


                <div style={{ marginTop: "2rem" }}>
                    <div className="grid subscriber-info-header">
                        <div className="col-4 md:col-4 lg:col-4 plan_description">{t("payments.date")}</div>
                        <div className="col-4 md:col-4 lg:col-4 plan_description">{t("payments.customer_info")}</div>
                    </div>

                    <div className="grid subscriber-info-div">
                        <div className="col-4 md:col-4 lg:col-4 plan_description">
                            <span className='cus_info plan_description'>{moment(paymentTrack?.receivedAt).format("ll")}</span>
                        </div>
                        <div className="col-4 md:col-4 lg:col-4 plan_description" style={{ display: "flex", flexDirection: "column" }}>
                            {state.paymentTrack?.customer.name ? <span className='cus_info plan_description'>{state.paymentTrack?.customer.name}</span> : " "}
                            <span className='cus_info cusNo plan_description'>{state.paymentTrack?.customer.phoneNo}</span>
                            <span className='plan_description'>{state.paymentTrack?.customer.email}</span>
                        </div>

                        <div className="col-4 md:col-4 lg:col-4" style={{ display: "flex", flexDirection: "column" }}>
                            {state.paymentTrack?.subscriber.businessCustomer.customerNum && <div className="subs_reference">
                                <span className="plan_description">{t("payments.customer_reference")}</span>:
                                {" "}<span className="plan_description" id="businessCustomerNum">{state.paymentTrack?.subscriber.businessCustomer.customerNum}</span></div>}
                            <span className="plan_description">{t("payments.customer_id")}:{" "}{state.paymentTrack?.subscriber.businessCustomer.reference}</span>
                        </div>
                    </div>
                </div>

                <div style={{ marginTop: "3rem", marginBottom: "0.3rem" }}>
                    <span style={{ color: "#66BB6A", fontWeight: "bold", fontSize: "16px" }}>{t("payments.paid")}</span>
                </div>
                <div className="border1">
                    <div className="border2">
                        <div className="grid" style={{ paddingLeft: "1rem" }}>
                            <div className="col-6 md:col-6 lg:col-6 plan_description_main_div">
                                {state.paymentTrack?.plan.name && <span className="plan-name_description">{state.paymentTrack?.plan.name}</span>}
                                {state.paymentTrack?.plan.description ? <span className="plan_description">{state.paymentTrack?.plan.description}</span> :
                                    <span className="plan_description">{t("payments.no_description")}</span>}
                            </div>
                            <div className="col-6 md:col-6 lg:col-6">
                                {state.paymentTrack?.amount ? <span className='paymentAmount'>{state.paymentTrack?.business.currency}{" "}{paymentTrack?.amount.toFixed(2)}</span> :
                                    <span className='paymentAmount'>{state.paymentTrack?.business.currency}{" "} {"00"}</span>}
                            </div>
                        </div>
                    </div>

                    {state.paymentTrack?.setupAmount !== null &&
                        <div className="border2">
                            <div className="grid" style={{ paddingLeft: "1rem", paddingTop: "0.5rem", alignItems: "center" }} >
                                <div className="col-6 md:col-6 lg:col-6">
                                    <span className="payments-setup">{t("payments.setup_fee")}</span>
                                </div>
                                <div className="col-6 md:col-6 lg:col-6">
                                    <span className="setupAmount">{state.paymentTrack?.business.currency}{" "}{state.paymentTrack?.setupAmount.toFixed(2)}</span>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className="grid" style={{ padding: "1rem 0rem 0rem 2rem" }}>
                    <div className="col-6 md:col-6 lg:col-6"><span className="total-label">{t("payments.total")}</span></div>
                    <div className="col-6 md:col-6 lg:col-6"><span className="payments-total">{state.paymentTrack?.business.currency}{" "}{total.toFixed(2)}</span></div>
                </div>

                <div className="grid" style={{ marginTop: "5rem" }}>
                    <div className="col-6 md:col-6 lg:col-6" >
                        <div className="grid span_divs reference_div" style={{ marginRight: "0.5rem" }}>
                            <div className="col-6 md:col-6 lg:col-6 left_span_divs"><span className="left_spans">{t("payments.payment_id_caps")}</span></div>
                            <div className="col-6 md:col-6 lg:col-6 right_span_divs"><span className="right_spans">{state.paymentTrack?.reference}</span> </div>
                        </div>
                        <div className="grid span_divs" style={{ marginTop: "1.5rem", marginRight: "0.5rem" }}>
                            <div className="col-6 md:col-6 lg:col-6 left_span_divs"><span className="left_spans">{t("payments.method")}</span></div>
                            <div className="col-6 md:col-6 lg:col-6 right_span_divs"><span className="right_spans">{state.paymentTrack?.paymentMode}</span></div>
                        </div>
                    </div>

                    <div className="col-6 md:col-6 lg:col-6" >
                        <div className="grid span_divs">
                            <div className="col-6 md:col-6 lg:col-6 left_span_divs "> <span className="left_spans">{t("payments.customer_payment")}</span></div>
                            <div className="col-6 md:col-6 lg:col-6 right_span_divs"><span className="right_spans">{state.paymentTrack?.business.currency}{" "}{paymentTrack?.amount.toFixed(2)}</span></div>
                        </div>
                        <div className="grid span_divs" style={{ marginTop: "1.5rem" }}>
                            <div className="col-6 md:col-6 lg:col-6 left_span_divs"><span className="left_spans">{t("payments.service_fee")}</span></div>
                            <div className="col-6 md:col-6 lg:col-6 right_span_divs"><span className="right_spans">{state.paymentTrack?.business.currency}{" "}{state.paymentTrack?.fees.toFixed(2)}</span></div>
                        </div>
                        <hr className='hr_tag' />
                        <div className="grid span_divs">
                            <div className="col-6 md:col-6 lg:col-6 left_span_divs"><span className="left_spans">{t("payments.business_received")}</span></div>
                            <div className="col-6 md:col-6 lg:col-6 right_span_divs"><span className="right_spans">{state.paymentTrack?.business.currency}{" "}
                                {state.paymentTrack?.redeemable.toFixed(2)}</span></div>
                        </div>

                        <div className='generation-div'>
                            <span style={{ marginRight: "0.5rem", marginLeft: "auto" }}>{t("payments.generation_txt")}{":"}</span>
                            <img src={logo} alt="babylon-layout" className='generation_img' />{"-"}{" "}
                            {newDate}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaymentDetail;