import React, { useEffect, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation, Trans } from "react-i18next";
import { Button } from 'primereact/button'
import { Card } from 'primereact/card';
import { FileUpload } from 'primereact/fileupload';
import { OverlayPanel } from 'primereact/overlaypanel';
import column from '../../../assets/images/column.png'
import selector from '../../../assets/images/Format Selector.png'
import DefMembershipActions from '../../../Redux/actions/defMembership-actions'
import BusinessCustomersActions from "../../../Redux/transition/customers/business-customer.reducer";

const Upload = ({ handleContinue, handleUpload, navigateToImportPage, state, setState }) => {
    const { t } = useTranslation("common");
    const fileUploadRef = useRef(null);
    const op1 = useRef(null)
    const dispatch = useDispatch();

    const defMembership = useSelector((state) => state.defMembership.defMembership);

    const getMembership = useCallback(() => { dispatch(DefMembershipActions.defMembershipRequest()) }, [dispatch]);
    const downloadSampleCsvFile = useCallback(() => dispatch(BusinessCustomersActions.downloadCsvRequest()), [dispatch]);

    useEffect(() => {
        if (!defMembership) {
            getMembership();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleUploadContinueBtn = () => {
        if (!state.file) return;
        handleUpload();
        handleContinue();
    }

    function handleClear() {
        setState((state) => ({ ...state, file: {} }));
        fileUploadRef.current.clear();
    }

    return (
        <div>
            <Card className='main-upload-card'>
                <div className='grid'>
                    <div className='col-6 pl-4 pt-5'>
                        <p className='title-text'>{t("business_customers.import-header")}</p>
                        <p className='sub_text mb-0 ' onClick={() => { downloadSampleCsvFile() }}>
                            <Trans i18nKey="business_customers.upload_text" components={{ 1: <strong className="download-csv" /> }} />
                        </p>

                        <div className='payment-added-alert tip-div'>
                            <span className='payment-added-alert1'>{t("business_customers.tip_1")}</span>
                            <br />
                            <span className='payment-added-alert2'>{t("business_customers.tip_2")}</span>
                        </div>
                        <div className='pop-over-div'>
                            <i className="pi pi-info-circle info-icon mr-1 hint-style" onClick={(e) => { op1.current.toggle(e) }} >
                            </i> <p className='payment_token'>{t("business_customers.pop_over")}</p>

                            <OverlayPanel ref={op1} className="custom-overlay-panel">
                                <div className="">
                                    <div className='mb-3 mt-2'>
                                        <div className='instruction2-header mb-2'>{t("business_customers.pop_over_header")}</div>
                                        <div>
                                            <p className='instruction2 mb-2'>
                                                <Trans i18nKey="business_customers.pop_over_text1" components={{ bold: <strong /> }} />
                                            </p>
                                            <p className='instruction2 mb-2'>
                                                <Trans i18nKey="business_customers.pop_over_text2" components={{ bold: <strong /> }} />
                                            </p>
                                            <p className='instruction2 mb-2'>
                                                <Trans i18nKey="business_customers.pop_over_text3" components={{ bold: <strong /> }} />
                                            </p>
                                            <img src={column} alt="col" />
                                            <p className='instruction2 mb-2'>
                                                <Trans i18nKey="business_customers.pop_over_text4"
                                                    components={{ bold: <strong />, 1: <strong style={{ color: "#9747ff", fontSize: "12px" }} />, 2: <strong style={{ fontStyle: "italic" }} /> }} />
                                            </p>
                                            <img src={selector} alt="col" />
                                        </div>
                                    </div>

                                </div>
                            </OverlayPanel>
                        </div>

                        <div className='upload-area'>
                            <div className="icon-div">
                                <i className="pi pi-upload download-icon"></i>
                            </div>
                            {defMembership?.role === ("OWNER" || "MANAGER") &&
                                (<div className="flex">
                                    <FileUpload
                                        ref={fileUploadRef}
                                        mode="basic"
                                        name="demo[]"
                                        className="import-csv-upload mt-6"
                                        onSelect={(e) => {
                                            setState((state) => ({ ...state, file: e.files[0] }));
                                        }}
                                        customUpload
                                        uploadHandler={handleUpload}
                                        accept=".csv"
                                        emptyTemplate={<p className="m-0"></p>}
                                    />
                                   {state.file?.name && <i className="pi pi-trash ml-2 remove-file" onClick={handleClear}/>}
                                </div>
                                )}
                            <p className="hint-text">{t("business_customers.hint")}</p>

                        </div>
                    </div>
                    <div className='col-6'>

                    </div>

                </div>
                <div id="upload-button">
                    <Button className='import_cancel_button' onClick={navigateToImportPage} >
                        {t("cancel")}
                    </Button>
                    <Button className='import_button' onClick={handleUploadContinueBtn} >
                        {t("continue")}
                    </Button>
                </div>

            </Card>
        </div>
    )
}
export default Upload;