import React, { useState, useEffect, useCallback, } from "react";
import { useSelector, useDispatch } from "react-redux";
import CustomDataTable from '../../../Components/CustomDataTable';
import { FullWidthCardTemplate ,MediumCardTemplate } from "../../Products/ProductSummaryInsights";
import PlanGroupActions from "../../../Redux/transition/plan-group/plan-group.reducer";
import DefMembershipActions from "../../../Redux/actions/defMembership-actions";
import SettlementActions from "../../../Redux/transition/settlement/settlement.reducer";
import WalletActions from "../../../Redux/transition/wallet/wallet.reducer";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "../../../Styles/pages/transactions.scss";
import "../../../Styles/pages/activities.scss";
import "../../../Styles/pages/customers.scss";
import Loading from "../../../Components/Loading";

const WithdrawalList = () => {

    const { t } = useTranslation("common");
    const [activeTab, setActiveTab] = useState(0);

    // Redux state from store
    const defMembership = useSelector((state) => state.defMembership.defMembership);
    const planGroup = useSelector((state) => state.planGroups.planGroup);
    const settlement = useSelector((state) => state.settlements.planSettlements);
    const fetchingOnePlanGroup = useSelector((state) => state.planGroups.fetchingOne);
    const fetchingOne = useSelector((state) => state.settlements.fetchingOne);
    const fetchingPlanSettlements = useSelector((state) => state.settlements.fetchingPlanSettlements);
    const walletStatistics = useSelector((state) => state.wallet.walletStatistics);

    //Redux dispatch actions
    const dispatch = useDispatch();
    const getList = useCallback((businessId, filter, options) => dispatch(SettlementActions.planSettlementsRequest(businessId, filter, options)), [dispatch])
    const getPlanGroup = useCallback((planId, options) => dispatch(PlanGroupActions.planGroupRequest(planId, options)), [dispatch])
    const getMembership = useCallback(() => { dispatch(DefMembershipActions.defMembershipRequest()); }, [dispatch]);
    const getWalletStatistics = useCallback((businessId, options) => { dispatch(WalletActions.walletStatisticsRequest(businessId, options)); }, [dispatch]);

    const [state, setState] = useState({
        planGroup: {},
        withdrawals: [],
        settlement: [],
        pending: [],
        processed: [],
        reversed: [],
        settlementList: [],
        walletStatisticsObject: [],
        loading: true,
        count: null,
        size: 15,
        page: 0,
        first: 0,
        sort: 'requestedOn,desc',
    })

    const search = useLocation().search;
    const id = new URLSearchParams(search).get('walletId');

    useEffect(() => {
        if (!defMembership) {
            getMembership();
        }
        if (defMembership) {
            getPlanGroup(id);
            getWalletStatistics(defMembership.business.id, {})
        }
        getList(id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (planGroup) {
            setState(state => { return { ...state, planGroup: planGroup } });
        }
        if (settlement) {
            setState((state) => { return { ...state, settlement } })
        }
        if (walletStatistics) {
            setState((state) => { return { ...state, walletStatisticsObject: walletStatistics.content } })
        }
    }, [planGroup, settlement, walletStatistics]);

    const pendingArray = state.settlement?.filter(item => item.status === 'PENDING');
    const processedArray = state.settlement?.filter(item => item.status === "PROCESSED");
    const reversedArray = state.settlement?.filter(item => item.status === "REVERSED");

    const mutablePendingArray = Array.from(pendingArray); //changes the pendingArray to a mutable array 
    const sortedPending = mutablePendingArray.sort((a, b) => new Date(b.requestedOn) - new Date(a.requestedOn));
    const threeRecentPending = sortedPending.slice(0, 3);

    const mutableProcessedArray = Array.from(processedArray); //changes the processedArray to a mutable array 
    const sortedProcessed = mutableProcessedArray.sort((a, b) => new Date(b.requestedOn) - new Date(a.requestedOn));
    const threeRecentProcessed = sortedProcessed.slice(0, 3);

    const mutablereversedArray = Array.from(reversedArray); //changes the reversedArray to a mutable array 
    const sortedReversed = mutablereversedArray.sort((a, b) => new Date(b.requestedOn) - new Date(a.requestedOn));
    const threeRecentReversed = sortedReversed.slice(0, 3);

    if (fetchingOnePlanGroup || fetchingOne || fetchingPlanSettlements) {
        return <Loading />;
    }
    const productAccountBalances = state.walletStatisticsObject.find(({ groupId }) => groupId === parseInt(id)) || null

    const statusTemplate = (rowData) => {
        if (rowData.status === 0) {
            return "";
        } else {
            if (rowData.status === "PROCESSED") {
                return <div className="statuses flex-column -ml-1 equal-margin">
                    <span className={`status-outline status-active status-${(rowData.status ? rowData.status.toLowerCase() : '')} status-content mb-1`}>{rowData.status}</span>

                    {rowData.status === "PROCESSED" ? <span className="trans-normal-text" style={{ display: "flex", flexDirection: "column", width: "auto" }}>
                        <span id="processed_on">{moment(rowData.requestedOn).format("ddd, MMM D, YYYY HH:MM A")}</span>
                    </span> : null}
                </div>
            }
            else if (rowData.status === "PENDING") {
                return <div className="statuses flex-column -ml-1 equal-margin">
                    <span className={`status-outline status-pending status-${(rowData.status ? rowData.status.toLowerCase() : '')} status-content mb-1`}>{rowData.status}</span>

                    {rowData.status === "PENDING" ? <span className="trans-normal-text" style={{ display: "flex", flexDirection: "column", width: "auto" }}>
                        <span id="processed_on">{moment(rowData.requestedOn).format("ddd, MMM D, YYYY HH:MM A")}</span>
                    </span> : null}
                </div>
            }
            else if (rowData.status === "REVERSED") {
                return <div className="statuses flex-column -ml-1 equal-margin">
                    <span className={`status-outline status-reversed status-${(rowData.status ? rowData.status.toLowerCase() : '')} status-content mb-1`}>{rowData.status}</span>

                    {rowData.status === "REVERSED" ? <span className="trans-normal-text" style={{ display: "flex", flexDirection: "column", width: "auto" }}>
                        <span id="processed_on">{moment(rowData.requestedOn).format("ddd, MMM D, YYYY HH:MM A")}</span>
                    </span> : null}
                    <span style={{ fontSize: "10px", height: "auto", marginTop: "1rem" }} className="reversalReason reversed_status">
                        {rowData.reversalReason}
                    </span>
                </div>
            }
        }
    }

    const customerBodyTemplate = (rowData) => {
        return <div className="flex equal-margin recipient-col">
            <div className="channel-logo">
                <div className="flex flex-column">
                    <div>
                        <span severity="info" id="channel_type">
                            {rowData.channel ? rowData.channel.type : "--"}
                        </span>
                    </div>
                    <div>
                        {
                            <img alt="channel logo" src={rowData.channel?.systemBank.logoUrl} width='50rem' height='40rem' />
                        }
                    </div>
                </div>
            </div>
            <div className="text-left">
                {rowData.channel ?
                    <span className="account_name">
                        {rowData.channel !== null ? rowData.channel.channelName : "--"}</span> : null
                }
                <div className="flex flex-column">
                    {rowData.channel ?
                        <span className="account_number">
                            {rowData.channel !== null && rowData.channel.channelName === null ?
                                <span style={{ fontWeight: "bold", color: "#000" }}>{rowData.channel.identifier}</span> : rowData.channel.identifier}
                        </span> : null
                    }
                </div>
                <div className="channel-info_div">
                    <div className="channel-info" >
                        <span id="channel_name">
                            {rowData.channel ? rowData.channel.systemBank.name : "--"}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    }

    const requestedByTemplate = (rowData) => {
        return <div className="customerBodyTemplate equal-margin">
            {rowData.requestedByName ?
                <span className="requested-info">{rowData.requestedByName === (null || '') ? rowData.requestedByLogin : rowData.requestedByName}</span> : "--"
            }
            {rowData.requestedByName ?
                <span className="requested_normal-info">{rowData.requestedByName !== null ? rowData.requestedByLogin : "--"}</span> : "--"
            }
        </div>
    }

    const amountTemplate = (rowData) => {
        const totalAmount = `${rowData.amount.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
        const fee = `${rowData.fee.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
        const deduction = `${rowData.deduction.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
        if (rowData.amount) {
            return <div className="flex flex-column text-left equal-margin amt-col">
                <span className="amount">
                    {`${defMembership.business.country.currency} ${totalAmount}`}
                </span>
                <div className="flex">
                    <p className="trans-label mb-0">{t("transaction.amount")}: </p>
                    <p className="fee ml-1">{` ${defMembership.business.country.currency} ${deduction}`}</p>
                </div>
                <div className="flex">
                    <p className="trans-label mb-0">{t("transaction.fee")}: </p>
                    <p className="fee ml-1">{` ${defMembership.business.country.currency} ${fee}`}</p>
                </div>


            </div>
        } else {
            return "--"
        }
    }

    const headerStyle = {
        fontSize: "12px",
        fontFamily: "Roboto",
        fontWeight: "bold",
        color: "#666678",
        textTransform: "capitalize"
    }

    const withdrawalsTableColumns = [
        {
            field: "status", body: statusTemplate, header: t("transaction.status"), headerClassName: "status-header", sort: true, alignHeader: "left",

        },
        {
            field: "requestedBy", body: requestedByTemplate, header: t("transaction.requested_by"), alignHeader: "left",
            headerStyle: headerStyle
        },
        {
            field: "recipientAccount", body: customerBodyTemplate, header: t("transaction.recipient"), alignHeader: "left",
            headerStyle: headerStyle
        },
        { field: "requested", body: amountTemplate, header: t("transaction.amount"), sort: true, alignHeader: "left", headerStyle: headerStyle }
    ]

    const tabs = [
        {
            label: "Processed",
            columns: withdrawalsTableColumns, value: processedArray
        },
        {
            label: "Pending",
            columns: withdrawalsTableColumns, value: pendingArray
        },
        {
            label: "Reversed",
            columns: withdrawalsTableColumns, value: reversedArray
        }
    ];

    const insightCards = [
        {
            type: "pendingThree",
            dataFromWithrawalList: threeRecentPending,
            title: `${t("transaction.pending")}`,
            cardInfo: t("transaction.pending_tooltip"),
            requestedBy: t("transaction.requested_by"),
            recipient: t("transaction.received_by"),
            dateClassName: "yellow-progress-txts"
        },
        {
            type: "processedThree",
            title: `${t("transaction.processed")}`,
            cardInfo: t("transaction.processed_tooltip"),
            dataFromWithrawalList: threeRecentProcessed,
            requestedBy: t("transaction.requested_by"),
            recipient: t("transaction.received_by"),
            dateClassName: "green-success-txts"
        },
        {
            type: "reversedThree",
            title: `${t("transaction.reversed")}`,
            cardInfo: t("transaction.reversed_tooltip"),
            dataFromWithrawalList: threeRecentReversed,
            requestedBy: t("transaction.requested_by"),
            recipient: t("transaction.received_by"),
            dateClassName: "red-cancelled-txts"
        }
    ]

    const mainCardWithdrawal=[{
        page:"withdrawal-list",
        productAccountBalances,
        withdrawalState: state,
        t,

    }]

    return (
        <div className="grid nested-grid withdrawListScreen">
            <div className="col-9">
                <div className="grid" >
                    <FullWidthCardTemplate summary={mainCardWithdrawal} />
                </div>

                <p className="wallet-titles mb-0">{t("products.summary-insights")}</p>

                <div className="grid mt-3">
                    <MediumCardTemplate summary={insightCards} />
                </div>
            </div>

            <div className="col-3">

            </div>


            <CustomDataTable
                value={tabs[activeTab].value}
                rows={10}
                paginator
                columns={tabs[activeTab].columns}
                tabs={tabs.map(({ label, i }) => ({ label, i }))}
                setActiveTab={setActiveTab}
                activeTab={activeTab}
                setState={setState}
                title={t("transaction.withdrawal_table_title")}
                children={null}
            />
        </div >
    )
}
export default WithdrawalList;