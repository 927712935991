import React, { useRef } from "react"
import moment from "moment";
import RoleAccess from "../../Components/RoleAccess";
import { FullWidthCardTemplate ,MediumCardTemplate, SmallCardTemplate } from "./ProductSummaryInsights";
import CustomOverlayPanel from './CustomOverlayComponent';
import { useNavigate } from "react-router-dom";

const ProductDetailCardInsights = (props) => {

      const op = useRef(null);
      const navigate = useNavigate();

      const createdOn = props.state.planGroup ? moment(props.state.planGroup.createdDate).format("MMMM DD YYYY") : "";
      const updatedOn = props.state.planGroup ? moment(props.state.planGroup.lastModifiedDate).format("MMMM DD YYYY") : "";

      const mainCardInfo = [
            {
                  page:"product-detail",
                  props,
                  createdOn,
                  updatedOn,
                  
            }
      ]

      const productSummaryInfo = [
            {
                  title: props.t("products.number_subscription"), subDesc: <span className="productSummaryInfo">{props.t("subscriptions")}</span>,
                  value: props.state.performance.activeSubscriptionCount, cardInfo: props.t("summary_reports.active_subs_info")
            },
            {
                  title: props.t("products.number_subscriber"), subDesc: <span className="productSummaryInfo">{props.t("subscribers")}</span>,
                  value: props.state.performance.activeCustomerCount, cardInfo: props.t("products.active_subscribers_tooltip")
            },
            {
                  title: props.t("products.number_active"), subDesc: <span className="productSummaryInfo">{props.t("products.plans")}</span>,
                  value: props.state.performance.activeTierCount, cardInfo: props.t("products.active_plans_tooltip")
            },
      ]
      const invitationContent = [
            {
                  title: props.t("products.highest_subscription"), subDesc: props.state.performance.highestSubscriptionTierName,
                  value: props.state.performance.activeSubscriptionCount, cardInfo: props.t("products.highest_subs_info")
            },
            {
                  title: props.t("products.highest_revenue"),
                  cardInfo: props.t("products.highest_rev_info"),
                  subDesc: props.state.performance.highestRevenueTierName,
                  value: <> <span>{props.defMembership.business?.currency}{" "}</span>
                        {props.state.performance.collected?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} </>
            }
      ]
      const productsDetailSnapshots = [
            {
                  type: "subs",
                  title: props.t("subscriptions"),
                  cardInfo: props.t("products.subs_info"),

                  subtitleNew: props.t("products.new"),
                  subsNewValue: props.state.performance?.newSubscriptionsCount,
                  subNewDesc: props.t("product.subscription"),
                  subsNewDesc: props.t("product.subscriptions"),

                  subtitleActive: props.t("products.active"),
                  subsActiveValue: props.state.performance?.activeSubscriptionCount,
                  subActiveDesc: props.t("product.subscription"),
                  subsActiveDesc: props.t("product.subscriptions"),

                  subtitleCompleted: props.t("products.completed"),
                  subsCompletedValue: props.state.performance?.completedSubscriptionCount,
                  subCompletedDesc: props.t("product.subscription"),
                  subsCompletedDesc: props.t("product.subscriptions"),

                  subtitlePaused: props.t("products.paused"),
                  subsPausedValue: props.state.performance?.pausedSubscriptionCount,
                  subPausedDesc: props.t("product.subscription"),
                  subsPausedDesc: props.t("product.subscriptions"),

                  subtitleCancelled: props.t("products.cancelled"),
                  subsCancelledValue: props.state.performance?.cancelledSubscriptionCount,
                  subCancelledDesc: props.t("product.subscription"),
                  subsCancelledDesc: props.t("product.subscriptions")
            },
            {
                  type: "payments",
                  title: props.t("common.payments"),
                  cardInfo: props.t("products.payments_info"),
                  collectedSubDesc: props.t("products.collected"),
                  collectedValue: <div className="flex-row"><span className="currency">{props.defMembership.business?.currency}</span> &nbsp;
                        <span className="card-values"> {props.state.performance.collected?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span> </div>,
                  revenueSubDesc: props.t("products.revenue"),
                  revenueValue: <div className="flex-row"> <span className="currency">{props.defMembership.business?.currency}</span>&nbsp;
                        <span className="card-values">{props.state.performance.revenue?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span></div>,
                  missedSubDesc: props.t("products.missed"),
                  missedValue: <div className="flex-row"> <span className="currency">{props.defMembership.business?.currency}{" "}</span>&nbsp;
                        <span className="card-values">{props.state.performance.missed?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span></div>
            },
            {
                  type: "withdrawals",
                  title: props.t("product_account.withdrawals"),
                  cardInfo: props.t("products.withdrawals_info"),
                  processedWithdrawalDesc: props.t("products.processed"),
                  processedWithdrawalValue: <div className="flex-row"> <span className="currency">{props.defMembership.business?.currency}</span>&nbsp;
                        <span className="card-values">{props.state.performance.approved?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span></div>,
                  pendingWithdrawalDesc: props.t("products.pending"),
                  pendingWithdrawalValue: <div className="flex-row"> <span className="currency">{props.defMembership.business?.currency}</span>&nbsp;
                        <span className="card-values">{props.state.performance.pending?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span></div>,
                  reversedWithdrawalDesc: props.t("products.reversed"),
                  reversedWithdrawalValue: <div className="flex-row"> <span className="currency">{props.defMembership.business?.currency}</span>&nbsp;
                        <span className="card-values">{props.state.performance.reversed?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span></div>
            }
      ]

      const sideMenu = [
            {
                  label: props.t("products.edit_product"),
                  icon: "pi-pencil",
                  onclick: () => { props.setProductInfoVisible(props.state.planGroup) },
            },
            {
                  label: props.t("products.archive_info"),
                  icon: "pi-lock",
                  onclick: () => { props.setArchive(true) },
            },
            {
                  label: props.t("products.add_product"),
                  icon: "pi-plus-circle",
                  onclick: () => {
                        navigate(`/products/plan/new/${props.state.planGroup.id}`, { state: { planGroup: props.state.planGroup, plan: true } })
                  },
            },
            {
                  label: props.t("products.send_money"),
                  icon: "layout-menuitem-icon pi pi-fw pi-send",
                  onclick: () => {
                        navigate("/money/send", { search: "walletId=" + props.planGroup.id })
                  },
            }
      ];

      const paymentSideMenu = [{
            paymentLabel: props.t("common.payments"),
            icon: "layout-menuitem-icon pi pi-fw pi-credit-card",
            ellipsisIcon: "p-button-icon p-c pi pi-ellipsis-v"
      }]

      const bottomSideMenu = [
            {
                  label: props.t("product_account.withdrawals"),
                  icon: "layout-menuitem-icon pi pi-fw pi-list",
                  onclick: () => {
                        navigate("/money/product_accounts/withdrawalsList", { state: props.planGroup, search: "walletId=" + props.planGroup.id })
                  }
            },
            {
                  label: props.t("products.subs_report"),
                  icon: "layout-menuitem-icon pi pi-book",
                  onclick: () => {
                        navigate("/reports/subscription/custom", { state: props.planGroup });
                  }
            }
      ]

      return (
            <div className="grid nested-grid">
                  <div className="col-9">
                        <div className="grid">
                              <FullWidthCardTemplate summary={mainCardInfo}/>
                        </div>

                        <span className="wallet-titles summary-title">{props.t("products.summary")}</span>

                        <div className="grid" id="summary-subscription-section">
                              <SmallCardTemplate header={props.t("products.summary")} summary={productSummaryInfo} loading={props.fetchingPerformance} />
                        </div>
                        <div className="grid" id="summary-subscription-section">
                              <SmallCardTemplate summary={invitationContent} loading={props.fetchingPerformance} />
                        </div>
                        <div className="grid" id="products-summary-section">
                              <MediumCardTemplate summary={productsDetailSnapshots} loading={props.fetchingPerformance} />
                        </div>

                  </div >
                  <div className="col-3">
                        <RoleAccess roles={["OWNER", "ADMIN"]}>
                              {props.state.planGroup.status !== "ARCHIVED" &&
                                    <div id="product-detail-side-menu">
                                          <p className="action-titles">{props.t("products.quick-actions")}</p>
                                          {sideMenu.map(({ label, icon, onclick }, index) => (
                                                <div className="side-menu-content" key={index}>
                                                      <div onClick={onclick}>
                                                            <i className={"pi " + icon} />
                                                            <span> {label}</span>
                                                      </div>
                                                </div>
                                          ))}
                                          <hr className="side-menu-hr" />
                                          <p className="action-titles">{props.t("products.associated-data")}</p>

                                          {paymentSideMenu.map(({ paymentLabel, icon, ellipsisIcon }, i) => (<div>
                                                <div className="side-menu-content" key={i}>
                                                      <div className="paymentSideMenu">
                                                            <i className={"pi " + icon} />
                                                            <p className="payments_p">{paymentLabel}</p>
                                                            <i onClick={(e) => {
                                                                  op.current.toggle(e)
                                                            }} id="paymentContextIcon" className={ellipsisIcon} />
                                                      </div>
                                                      <CustomOverlayPanel op={op}>
                                                            <div>
                                                                  <ul className="user-profile-items">
                                                                        <li>
                                                                              <div className="p-link list-button" >
                                                                                    <span className="list-items" onClick={() => {
                                                                                          navigate("/payments", { state: { showPaymentContent: "collected", id: props.planGroup.id, name: props.planGroup.name, phone: props.planGroup.business?.phoneNo } });
                                                                                    }}>{props.t("payments.collected")}</span>
                                                                              </div>
                                                                        </li>
                                                                  </ul>
                                                                  <ul className="user-profile-items">
                                                                        <li>
                                                                              <div className="p-link list-button" >
                                                                                    <span className="list-items" onClick={() => {
                                                                                          navigate("/payments", { state: { showPaymentContent: "missed", id: props.planGroup.id, name: props.planGroup.name } });
                                                                                    }}>{props.t("payments.missed")}</span>
                                                                              </div>
                                                                        </li>
                                                                  </ul>
                                                                  <ul className="user-profile-items">
                                                                        <li>
                                                                              <div className="p-link list-button">
                                                                                    <span className="list-items" onClick={() => {
                                                                                          navigate("/payments", { state: { showPaymentContent: "external", id: props.planGroup.id, name: props.planGroup.name } });
                                                                                    }}>{props.t("payments.external")}</span>
                                                                              </div>
                                                                        </li>
                                                                  </ul>
                                                            </div>
                                                      </CustomOverlayPanel>
                                                </div>
                                          </div>))}
                                          {bottomSideMenu.map(({ label, icon, onclick }, i) => (
                                                <div className="side-menu-content" key={i}>
                                                      <div onClick={onclick}>
                                                            <i className={"pi " + icon} />
                                                            <span>{label}</span>
                                                      </div>
                                                </div>
                                          ))}
                                    </div>}
                        </RoleAccess>
                  </div>
            </div >
      )
}

export default ProductDetailCardInsights;