/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DefMembershipActions from "../../Redux/actions/defMembership-actions";
import { useTranslation } from "react-i18next";
import Customer from "./customers";
import Import from "./import";
import { Button } from "primereact/button";
import { useNavigate, useLocation } from "react-router-dom";
import "../../Styles/pages/customers.scss";
import "../../Styles/pages/dashboard.scss";
import { TabMenu } from "primereact/tabmenu";


const CustomersIndex = () => {
    const { t } = useTranslation("common");
    const location = useLocation();
    const [activeIndex, setActiveIndex] = useState(0)
    const defMembership = useSelector((state) => state.defMembership.defMembership);
    const dispatch = useDispatch();
    const getMembership = useCallback(() => { dispatch(DefMembershipActions.defMembershipRequest()) }, [dispatch]);
    const navigate = useNavigate();

    useEffect(() => {
        if (!defMembership) {
            getMembership();
        }
    }, []);

    useEffect(() => {
        if (location.state?.activeIndex !== undefined) {
            setActiveIndex(location.state.activeIndex);
        }
    }, [location.state]);

    const handleImport =()=>{
        navigate('/subscribers/imports');
    }

    return (
        <div className="customerContent">
            <div className="main-container">
                <TabMenu className={"p-tabMenu customerTabs"}
                    activeIndex={activeIndex}
                    onTabChange={(e) => { setActiveIndex(e.index) }}
                    model={[{ label: t("business_customers.customers"), className: "tab tab1" },
                    { label: t("business_customers.imports"), className: "tab edge-tab-end" }]} />

                {activeIndex === 1 && 
                    <Button className="import_button" onClick={handleImport}>
                        <i className="icon pi-left pi pi-plus-circle ">
                            <span className="addFav-btn-text ml-2">{t("business_customers.import")}</span>
                        </i>
                    </Button>
                }

            </div>
            {activeIndex === 0 && <Customer />}
            {activeIndex === 1 && <Import />}
        </div>
    )
}

export default CustomersIndex;